<template>
  <div class="share">
    <div class="card-cover">
      <div class="title">分享卡片封面设置</div>
      <div class="share-content">
        <div class="preview">
          <div class="header-titile">桔子呀</div>
          <div class="cover_picture">
            <img :src="imgUrl + 'distribution/share-img.png'" alt="" />
            <img v-show="cover_picture" :src="cover_picture" alt="" />
          </div>
        </div>
        <div class="set-img">
          <el-form label-width="160px">
            <el-form-item label="分享卡片封面：">
              <p style="line-height: 25px; margin-top: 8px">
                用户分享的推广将以分享卡片的形式呈现。(邀请分销员)
                <br />
                建议尺寸：500像素*400像素，图片大小不得超过1M。
              </p>
            </el-form-item>
            <el-form-item>
              <ReadyUploadSource
                :showStyle="{
                  width: '120px',
                  height: '120px',
                }"
                type="image"
                :path="cover_picture"
                :isNeedHiddenUp="true"
                @removeThis="() => (cover_picture = '')"
                @getSource="val => (cover_picture = val.path)"
              ></ReadyUploadSource>
            </el-form-item>
            <el-form-item>
              <el-button @click="cover_picture = shareSetInfo.default_cover_picture,$message.success('已经重置为系统默认')" size="small" type="text">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="apply-cover">
      <div class="title">推广申请海报设置</div>
      <div class="share-content">
        <div class="preview">
          <div class="header-titile">推广申请</div>
          <img :src="apply_picture" alt="" />
          <img :src="imgUrl + 'distribution/share-shop.png'" alt="" />
          <img :src="imgUrl + 'distribution/share-join.png'" alt="" />
        </div>
        <div class="set-img">
          <el-form label-width="160px">
            <el-form-item label="推广申请海报：">建议尺寸：750像素*670像素，图片大小不得超过1M。</el-form-item>
            <el-form-item>
              <ReadyUploadSource
                :showStyle="{
                  width: '120px',
                  height: '120px',
                }"
                type="image"
                :path="apply_picture"
                :isNeedHiddenUp="true"
                @removeThis="() => (apply_picture = '')"
                @getSource="val => (apply_picture = val.path)"
              ></ReadyUploadSource>
            </el-form-item>
            <el-form-item>
              <el-button @click="apply_picture = shareSetInfo.default_apply_picture,$message.success('已经重置为系统默认')" size="small" type="text">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="share-cover">
      <div class="title">推广分享海报设置</div>
      <div class="share-content">
        <div class="preview">
          <div class="header-titile">邀请好友</div>
          <div v-show="share_picture" class="share-picture">
            <img class="back" :src="share_picture" alt="" />
            <div class="user-info">
              <div class="name">
                <img :src="imgUrl + 'distribution/avatar.jpg'" alt="" />
                <p>桔子呀</p>
                <p>边赚钱边购物 好物等你来拿</p>
              </div>
              <img src="@/assets/image/qrcode.png" alt="" />
            </div>
          </div>
          <img class="share-icon" :src="imgUrl + 'distribution/share-icon.png'" alt="" />
        </div>
        <div class="set-img">
          <el-form label-width="160px">
            <el-form-item label="邀请分销员海报：">选择海报背景</el-form-item>
            <el-form-item>
              <div class="poster-list">
                <div class="item" v-for="(item, index) in shareSetInfo.default_share_picture" :key="index">
                  <img :src="item.img" alt="" />
                  <div class="radio">
                    <img @click="selectSharePoster(item)" :src="item.is_select ? imgUrl + 'distribution/icon2.png' : imgUrl + 'distribution/icon1.png'" alt="" />
                  </div>
                </div>
              </div>
              <p>自定义海报：建议尺寸：582像素*1172像素，图片大小不得超过1M</p>
            </el-form-item>
            <el-form-item>
              <ReadyUploadSource
                :showStyle="{
                  width: '120px',
                  height: '120px',
                }"
                type="image"
                :path="share_picture"
                :isNeedHiddenUp="true"
                @removeThis="() => (share_picture = '')"
                @getSource="val => (share_picture = val.path)"
              ></ReadyUploadSource>
            </el-form-item>
            <el-form-item>
              <el-button @click="resetSharePicture" size="small" type="text">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <Preservation @preservation="saveSet"></Preservation>
  </div>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import { imgUrlHead } from '@/util/config.js';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
  },
  data() {
    return {
      imgUrl: imgUrlHead,
      cover_picture: '',
      apply_picture: '',
      share_picture: '',
      shareSetInfo: {},
    };
  },
  created() {
    this.getShareSetInfo();
  },
  methods: {
    resetSharePicture() {
      this.share_picture = this.shareSetInfo.default_share_picture[0].img;
      this.shareSetInfo.default_share_picture.map(item => (item.is_select = !1));
      this.shareSetInfo.default_share_picture[0].is_select = !0;
      this.$message.success("已经重置为系统默认")
    },
    getShareSetInfo() {
      this.$axios.post('/Beauty' + this.$api.distribution.shareSetInfo).then(res => {
        if (res.code == 0) {
          let result = res.result;
          let shareImg = [];
          result.default_share_picture.map(item => {
            shareImg.push({
              is_select: !1,
              img: item,
            });
          });
          shareImg.map(item => {
            if (item.img == result.share_picture) item.is_select = !0;
          });
          result.default_share_picture = shareImg;
          this.cover_picture = result.cover_picture;
          this.apply_picture = result.apply_picture;
          this.share_picture = result.share_picture;
          this.shareSetInfo = res.result;
        }
      });
    },
    saveSet() {
      this.$axios
        .post('/Beauty' + this.$api.distribution.shareSetEdit, {
          cover_picture: this.cover_picture,
          apply_picture: this.apply_picture,
          share_picture: this.share_picture,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    selectSharePoster(row) {
      this.shareSetInfo.default_share_picture.map(item => (item.is_select = !1));
      row.is_select = !row.is_select;
      this.share_picture = row.img;
    },
  },
};
</script>

<style lang="less" scoped>
.share {
  padding-bottom: 40px;
}
.title {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f7f8fa;
  padding-left: 10px;
  margin-bottom: 20px;
  &:before {
    content: '';
    width: 4px;
    display: inline-block;
    height: 14px;
    background: #1467ff;
    margin-right: 6px;
  }
}
.card-cover {
  margin-bottom: 40px;
  .share-content {
    display: flex;
    .preview {
      width: 340px;
      height: 627px;
      background: url('~@/assets/share-back.png') no-repeat;
      background-size: 100% 100%;
      .header-titile {
        margin-top: 50px;
        text-align: center;
        font-weight: 600;
      }
      .cover_picture {
        width: 316px;
        height: 335px;
        margin: 0 auto;
        margin-top: 20px;
        position: relative;
        img:nth-child(1) {
          width: 100%;
          height: 100%;
        }
        img:nth-child(2) {
          width: 236px;
          height: 185px;
          position: absolute;
          left: 57px;
          top: 78px;
        }
      }
    }
  }
}
.apply-cover {
  margin-bottom: 40px;
  .share-content {
    display: flex;
    .preview {
      width: 340px;
      height: 627px;
      background: url('~@/assets/share-back.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
      text-align: center;
      .header-titile {
        margin-top: 50px;
        text-align: center;
        font-weight: 600;
      }
      img:nth-child(2) {
        width: 322px;
        margin-top: 15px;
      }
      img:nth-child(3) {
        width: 293px;
        height: 226px;
        bottom: 74px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }
      img:nth-child(4) {
        width: 293px;
        height: 47px;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.share-cover {
  .share-content {
    display: flex;
    .preview {
      width: 340px;
      height: 627px;
      background: url('~@/assets/share-back.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      position: relative;
      .share-icon {
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        height: 71px;
      }
      .header-titile {
        margin-top: 50px;
        text-align: center;
        font-weight: 600;
      }
      .share-picture {
        width: 270px;
        height: 445px;
        position: relative;
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 10px;
        .back {
          width: 100%;
          height: 100%;
        }
        .user-info {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 110px;
          background: #fff;
          border-radius: 10px 10px 0 0;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          .name {
            text-align: left;
            img {
              width: 40px;
              height: 40px;
            }
            p:nth-child(2) {
              font-size: 12px;
              margin-top: 3px;
            }
            p:nth-child(3) {
              font-size: 12px;
              font-weight: bold;
              margin-top: 3px;
            }
          }
          img {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
  }
  .poster-list {
    display: flex;
    .item {
      margin-right: 54px;
      &:last-child {
        margin: 0;
      }
      img {
        width: 172px;
        height: 306px;
      }
      .radio {
        text-align: center;
        margin-top: 11px;
        img {
          cursor: pointer;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
</style>