<template>
  <div class="commission">
    <el-form label-width="120px">
      <el-form-item label="分佣等级：">
        <el-radio-group v-model="formInfo.type">
          <el-radio :label="0">不支持平级分佣</el-radio>
          <el-radio :label="1">支持平级分佣</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <p style="color: #999">不支持平级分佣：平级之间不分佣，即使是直推，也是上级才可以分佣金</p>
        <p style="color: #999">支持平级分佣：平级之间可以拿直推奖励</p>
      </el-form-item>
      <!-- <el-form-item label="股东分红：">
        <el-switch v-model="formInfo.is_level_dividend" :active-value="1" :inactive-value="0"></el-switch>
        <template v-if="formInfo.is_level_dividend">
          <el-form-item label="股东身份选择：">
            <el-select v-model="formInfo.level_id">
              <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="股东分红设置：">
            <el-radio-group v-model="formInfo.level_dividend_type">
              <el-radio :label="1">团队业绩（包括平级，两级）</el-radio>
              <el-radio :label="2">团队业绩（包括平级，只一级）</el-radio>
              <el-radio :label="3">团队业绩（不包括平级）</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="股东分佣比例：">
            <el-input v-model="formInfo.level_dividend_rate">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </template>
      </el-form-item> -->
    </el-form>
    <Preservation @preservation="setInfo()"></Preservation>
  </div>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  props: {
    formInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    setInfo() {
      let data = {};
      data.type = this.formInfo.type;
      data.is_level_dividend = this.formInfo.is_level_dividend;
      if (data.is_level_dividend) {
        data.level_id = this.formInfo.level_id;
        data.level_dividend_type = this.formInfo.level_dividend_type;
        data.level_dividend_rate = this.formInfo.level_dividend_rate;
      }
      this.$axios.post('/Beauty' + this.$api.distribution.editCentCommission, data).then(res => {
        if (res.code == 0) {
          this.$message.success('保存成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.commission {
  height: 100%;
  .el-form > .el-form-item {
    width: 920px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 22px;
  }
}
</style>
